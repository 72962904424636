import { push } from "connected-react-router";
import AppBarComponent, { ConnectedDispatch, ConnectedState } from "./app-bar.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/app.state";
import {
  getCurrentTitle,
  getCurrentVvvo,
  isAllChecklistenEingabenSynced,
} from "../../store/common/common.selectors";
import { logout } from "../../store/logout/logout.actions";
import { getChecklistenEingabenStatus } from "../../store/checklisten-eingabe/checklisten-engabe.selectors";
import { getProfileStatus } from "../../store/contact-details/contact-details.selectors";
import { getChangeEmailStatus } from "../../store/change-email/change-email.selector";
import { getChangePasswordStatus } from "../../store/change-password/change-password.selector";
import { getBetriebstaetteStatus } from "../../store/betriebstaette-auth/betriebstaette-auth.selectors";
import { getBetriebeStatus } from "../../store/betriebe/betriebe.selectors";
import { getLogoutStatus } from "../../store/logout/logout.selectors";
import { init } from "../../store/init/init.actions";
import { updateBetriebe } from "../../store/betriebe/betriebe.actions";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  navigateTo: (path: string) => dispatch(push(path)),
  logout: () => dispatch(logout()),
  loadAllData: () => dispatch(init(true)),
  updateBetriebe: () => dispatch(updateBetriebe()),
});

const mapStateToProps = (state: AppState): ConnectedState => ({
  appTitle: getCurrentTitle(state),
  selectedVvvo: getCurrentVvvo(state),
  checklistenEingabenStatus: getChecklistenEingabenStatus(state),
  profileStatus: getProfileStatus(state),
  changeEmailStatus: getChangeEmailStatus(state),
  changePasswordStatus: getChangePasswordStatus(state),
  betriebstaetteStatus: getBetriebstaetteStatus(state),
  betriebeStatus: getBetriebeStatus(state),
  logoutStatus: getLogoutStatus(state),
  isAllChecklistenEingabenSynced: isAllChecklistenEingabenSynced(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComponent);
