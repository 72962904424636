import { IBetriebDto } from "../../api/backend-api-v1";
import { loadFailed, LoadingStatus, loadInitial, loadPending, loadSuccess } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { BetriebeActionType } from "./betriebe.actions";

export interface BetriebeState {
  betriebe: { [betriebId: number]: IBetriebDto };
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
}

export const initialState: BetriebeState = {
  betriebe: {},
  loadStatus: loadInitial(),
  lastFetched: undefined,
};

export default createReducer(initialState, {
  [BetriebeActionType.LOAD_BETRIEBE](state: BetriebeState): BetriebeState {
    return { ...state, loadStatus: loadPending() };
  },
  [BetriebeActionType.REFRESH_BETRIEBE](state: BetriebeState): BetriebeState {
    return { ...state, loadStatus: loadInitial() };
  },
  [BetriebeActionType.LOAD_BETRIEBE_SUCCESS](
    state: BetriebeState,
    action: Action<{
      betriebe: { [betriebId: string]: IBetriebDto };
    }>
  ): BetriebeState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      betriebe: action.payload.betriebe,
      lastFetched: new Date(Date.now()),
    };
  },
  [BetriebeActionType.LOAD_BETRIEBE_FAILURE](state: BetriebeState, action: Action<string>): BetriebeState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
      betriebe: {},
      lastFetched: new Date(Date.now()),
    };
  },
  [BetriebeActionType.SAVE_BETRIEB](state: BetriebeState): BetriebeState {
    return { ...state, loadStatus: loadPending() };
  },
  [BetriebeActionType.SAVE_BETRIEB_SUCCESS](
    state: BetriebeState,
    action: Action<IBetriebDto>
  ): BetriebeState {
    const betrieb = action.payload;
    return {
      ...state,
      betriebe: { ...state.betriebe, [betrieb.id!]: betrieb },
      lastFetched: new Date(Date.now()),
      loadStatus: loadSuccess(),
    };
  },
  [BetriebeActionType.SAVE_BETRIEB_FAILURE](state: BetriebeState, action: Action<string>): BetriebeState {
    return {
      ...state,
      lastFetched: new Date(Date.now()),
      loadStatus: loadFailed(action.payload),
    };
  },
  [BetriebeActionType.DELETE_BETRIEB](state: BetriebeState): BetriebeState {
    return { ...state, loadStatus: loadPending() };
  },
  [BetriebeActionType.DELETE_BETRIEB_SUCCESS](state: BetriebeState): BetriebeState {
    return {
      ...state,
      loadStatus: loadSuccess(),
    };
  },
  [BetriebeActionType.DELETE_BETRIEB_FAILURE](state: BetriebeState, action: Action<string>): BetriebeState {
    return {
      ...state,
      loadStatus: loadFailed(action.payload),
    };
  },
  [BetriebeActionType.UPDATE_BETRIEB](state: BetriebeState, action: Action<IBetriebDto>): BetriebeState {
    const betrieb = action.payload;
    return {
      ...state,
      betriebe: { ...state.betriebe, [betrieb.id!]: betrieb },
    };
  },
  [BetriebeActionType.RESET_BETRIEBE](state: BetriebeState): BetriebeState {
    return initialState;
  },
  [BetriebeActionType.UPDATE_BETRIEBE](state: BetriebeState): BetriebeState {
    return { ...state, loadStatus: loadPending() };
  },
});
