import { AppState } from "../app.state";
import { IBetriebDto } from "../../api/backend-api-v1";

export const getBetriebe = (state: AppState): { [betriebId: number]: IBetriebDto } => state.betriebe.betriebe;

export const getBetriebIds = (state: AppState) => Object.keys(state.betriebe.betriebe) ?? [];

export const getBetriebeStatus = (state: AppState) => state.betriebe.loadStatus;

export const getBetriebById = (state: AppState, betriebId: number): IBetriebDto =>
  state.betriebe.betriebe[betriebId];
