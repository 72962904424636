import { connect } from "react-redux";
import AppComponent, { ConnectedState, ConnectedDispatch } from "./app.component";
import { AppState } from "./store/app.state";
import { getLoginStatus, getIsLoggedIn } from "./store/login/login.selectors";
import { Dispatch } from "redux";
import { toggleIsAppUpdateAvailable } from "./store/common/common.actions";
import { syncChecklistenEingabe } from "./store/checklisten-eingabe/checklisten-eingabe.actions";
import { init, initTranslations } from "./store/init/init.actions";
import { getTranslationsLoaded, getTranslationsStatus } from "./store/i18n/i18n.selectors";
import { getAllDataLoadingStatus } from "./store/common/common.selectors";

const mapStateToProps = (state: AppState): ConnectedState => ({
  loginStatus: getLoginStatus(state),
  translationStatus: getTranslationsStatus(state),
  translationLoaded: getTranslationsLoaded(state),
  isLoggedIn: getIsLoggedIn(state),
  allDataLoadingStatus: getAllDataLoadingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  syncChecklistenEingabe: () => dispatch(syncChecklistenEingabe()),
  setIsAppUpdateAvailable: (isAvailable: boolean) => dispatch(toggleIsAppUpdateAvailable(isAvailable)),
  initTranslations: () => dispatch(initTranslations()),
  init: () => dispatch(init(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
