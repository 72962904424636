import { LoadingStatus, loadInitial, loadPending, loadSuccess, loadFailed } from "../loading-status";
import createReducer from "../createReducer";
import { Action } from "../action";
import { LoginActionType } from "./login.actions";
import { IAuthResponseDto } from "../../api/backend-api-v1";

export interface LoginState {
  token: string;
  loadStatus: LoadingStatus;
  lastFetched: Date | undefined;
}

export const initialState: LoginState = {
  token: "",
  loadStatus: loadInitial(),
  lastFetched: undefined,
};

export default createReducer(initialState, {
  [LoginActionType.FETCH_USER](state: LoginState): LoginState {
    return { ...state, loadStatus: loadPending() };
  },
  [LoginActionType.FETCH_USER_SUCCESS](state: LoginState, action: Action<IAuthResponseDto>): LoginState {
    return {
      ...state,
      loadStatus: loadSuccess(),
      ...action.payload,
      lastFetched: new Date(Date.now()),
    };
  },
  [LoginActionType.FETCH_USER_FAILURE](state: LoginState, action: Action<string>): LoginState {
    return { ...state, loadStatus: loadFailed(action.payload), lastFetched: new Date(Date.now()) };
  },
  [LoginActionType.REMOVE_LOGIN_INFO](state: LoginState): LoginState {
    return { ...initialState };
  },
});
