import { AppState } from "../../store/app.state";
import BetriebeComponent, { ConnectedState } from "./betriebe.component";
import { connect } from "react-redux";
import { getBetriebe, getBetriebeStatus } from "../../store/betriebe/betriebe.selectors";
import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { ConnectedDispatch } from "./betriebe.component";
import { setCurrentTitle, setCurrentVvvo } from "../../store/common/common.actions";
import { getTranslationsStatus } from "../../store/i18n/i18n.selectors";
import { getLogoutStatus } from "../../store/logout/logout.selectors";
import { getChecklistenEingaben } from "../../store/checklisten-eingabe/checklisten-engabe.selectors";
import { showFailedRequest } from "../../store/notifications/notifications.actions";

const mapStateToProps = (state: AppState): ConnectedState => {
  const betriebe = getBetriebe(state);
  return {
    betriebe: betriebe ? Object.values(betriebe) : [],
    betriebeStatus: getBetriebeStatus(state),
    translationsStatus: getTranslationsStatus(state),
    logoutStatus: getLogoutStatus(state),
    checklistenEingaben: getChecklistenEingaben(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  navigateTo: (path: string) => dispatch(push(path)),
  setCurrentVvvo: (vvvo: string) => dispatch(setCurrentVvvo(vvvo)),
  setCurrentTitle: (title: string) => dispatch(setCurrentTitle(title)),
  showFailedRequest: (message: string) => dispatch(showFailedRequest(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BetriebeComponent);
