import { Action } from "../action";

export enum CommonActionType {
  SET_CURRENT_TITLE = "SET_CURRENT_TITLE",
  SET_CURRENT_VVVO = "SET_CURRENT_VVVO",
  OPEN_MODAL_WINDOW = "OPEN_MODAL_WINDOW",
  IS_APP_UPDATE_AVAILABLE = "IS_APP_UPDATE_AVAILABLE",
  SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE",
  SHOULD_LOAD_CHECKLISTEN_EINGABEN = "SHOULD_LOAD_CHECKLISTEN_EINGABEN",
  CHECKLISTEN_EINGABEN_ARE_SYNCED = "CHECKLISTEN_EINGABEN_ARE_SYNCED",
  LOAD_ALL_DATA = "LOAD_ALL_DATA",
  LOAD_ALL_DATA_SUCCESS = "LOAD_ALL_DATA_SUCCESS",
  LOAD_ALL_DATA_FAILURE = "LOAD_ALL_DATA_FAILURE",
}

export const setCurrentTitle = (title: string): Action<string> => ({
  type: CommonActionType.SET_CURRENT_TITLE,
  payload: title,
});

export const setCurrentVvvo = (vvvo: string): Action<string> => ({
  type: CommonActionType.SET_CURRENT_VVVO,
  payload: vvvo,
});

export const openModalWindow = (isOpen: boolean): Action<boolean> => ({
  type: CommonActionType.OPEN_MODAL_WINDOW,
  payload: isOpen,
});

export const toggleIsAppUpdateAvailable = (isAvailable: boolean): Action<boolean> => ({
  type: CommonActionType.IS_APP_UPDATE_AVAILABLE,
  payload: isAvailable,
});

export const setCurrentLanguage = (language: string): Action<string> => ({
  type: CommonActionType.SET_CURRENT_LANGUAGE,
  payload: language,
});

export const shouldLoadChecklistenEingaben = (shouldLoad: boolean): Action<boolean> => ({
  type: CommonActionType.SHOULD_LOAD_CHECKLISTEN_EINGABEN,
  payload: shouldLoad,
});

export const checklistenEingabenAreSynced = (isSynced: boolean): Action<boolean> => ({
  type: CommonActionType.CHECKLISTEN_EINGABEN_ARE_SYNCED,
  payload: isSynced,
});

export const loadAllData = (): Action<null> => ({
  type: CommonActionType.LOAD_ALL_DATA,
  payload: null,
});

export const loadAllDataSuccuss = (): Action<null> => ({
  type: CommonActionType.LOAD_ALL_DATA_SUCCESS,
  payload: null,
});

export const loadAllDataFailure = (errorMessage: string): Action<string> => ({
  type: CommonActionType.LOAD_ALL_DATA_FAILURE,
  payload: errorMessage,
});
