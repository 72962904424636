import { Action } from "../action";
import { IAuthResponseDto } from "../../api/backend-api-v1";

export enum LoginActionType {
  FETCH_USER = "FETCH_USER",
  FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE = "FETCH_USER_FAILURE",
  FILL_OUT_PROFILE_SUCCESS = "FILL_OUT_PROFILE_SUCCESS",
  REMOVE_LOGIN_INFO = "REMOVE_LOGIN_INFO",
}

export interface LoginDto {
  username: string;
  password: string;
}

export const fetchUser = (userdata: LoginDto): Action<LoginDto> => ({
  type: LoginActionType.FETCH_USER,
  payload: userdata,
});

export const fetchUserSuccess = (data: IAuthResponseDto): Action<IAuthResponseDto> => ({
  type: LoginActionType.FETCH_USER_SUCCESS,
  payload: data,
});

export const fetchUserFailure = (errorMessage: string): Action<string> => ({
  type: LoginActionType.FETCH_USER_FAILURE,
  payload: errorMessage,
});

export const fillOutProfileSuccess = (isFillOut: boolean): Action<boolean> => ({
  type: LoginActionType.FILL_OUT_PROFILE_SUCCESS,
  payload: isFillOut,
});

export const removeLoginInfo = (): Action<null> => ({
  type: LoginActionType.REMOVE_LOGIN_INFO,
  payload: null,
});
