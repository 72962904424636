import { FunctionComponent, forwardRef, useEffect, useState, useCallback } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import useMaterialTableState from "./checklisten-overview.hooks";
import useStyles from "./checklisten-overview.styles";
import DeleteBusinessPremisesContainer from "../../components/modal-windows/delete-business-premises/delete-business-premises.container";
import AddChecklistenContainer from "../../components/modal-windows/add-checkliste/add-checkliste.container";
import OpenDraftContainer from "../../components/modal-windows/open-draft/open-draft.container";
import CopyChecklistenEingabeContainer from "../../components/modal-windows/copy-checklisten-eingabe/copy-checklisten-eingabe.container";
import AppButton from "../../components/common/app-button/app-button";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import TransitionsModal from "../../components/common/modal/modal-window";
import PreLoader from "../../components/preloader/preloader.component";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import convertBase64ToPdf from "../../utils/convert-base64";
import generateLinkForPdf from "../../utils/generate-link-for-pdf";
import { Props } from "./checklisten-overview.types";
import { useTranslation } from "react-i18next";
import { Button, Paper, Typography } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";
import { Colors } from "../../theme/colors";
import { ButtonSize } from "../../shared/buttons-style";
import { ISyncedChecklistenEingabeDto } from "../../db/database";

const ChecklistenOverviewComponent: FunctionComponent<Props> = props => {
  const {
    checklistenEingaben,
    betriebId,
    betriebName,
    betriebVvvo,
    setCurrentTitle,
    setCurrentVvvo,
    deleteChecklistenEingabe,
    checklistenEingabenStatus,
    getPdfChecklistenEingabe,
    checklistenEingabePdf,
    resetPdfChecklistenEingabe,
    shouldLoadChecklistenEingaben,
    loadChecklistenEingaben,
    loadChecklisten,
    updateChecklistenEingaben,
    logoutStatus,
    betrieb,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const { state, loadRows } = useMaterialTableState(checklistenEingaben, betriebId, classes);

  const [isAddChecklisteOpen, setIsAddChecklisteOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenDeleteBusinessPremises, setIsOpenDeleteBusinessPremises] = useState(false);
  const [checklistenEingabeUniqueId, setChecklistenEingabeUniqueId] = useState<string>("");
  const [isDraftDialogOpen, setIsDraftDialogOpen] = useState(false);
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
  const [draftChecklistenEingabe, setDraftChecklistenEingabe] = useState<
    ISyncedChecklistenEingabeDto | undefined
  >(undefined);
  const [checklistenEingabeForCopy, setChecklistenEingabeForCopy] = useState<
    ISyncedChecklistenEingabeDto | undefined
  >(undefined);

  const deleteBusinessPremisesHandler = useCallback(() => {
    setIsOpenDeleteBusinessPremises(!isOpenDeleteBusinessPremises);
  }, [isOpenDeleteBusinessPremises]);

  const onDraftClick = useCallback(
    (checklistenEingabe: ISyncedChecklistenEingabeDto) => {
      setIsDraftDialogOpen(!isDraftDialogOpen);
      setDraftChecklistenEingabe(checklistenEingabe);
    },
    [isDraftDialogOpen]
  );

  const handleCopyButton = useCallback(
    (checklistenEingabe: ISyncedChecklistenEingabeDto) => {
      setIsCopyDialogOpen(!isCopyDialogOpen);
      setChecklistenEingabeForCopy(checklistenEingabe);
    },
    [isCopyDialogOpen]
  );

  const handleOpenChecklisteAsPdf = useCallback(
    (checklisteEingabeId: string) => {
      getPdfChecklistenEingabe(checklisteEingabeId);
    },
    [getPdfChecklistenEingabe]
  );

  useEffect(() => {
    window.onload = () => {
      updateChecklistenEingaben(true);
    };
  }, [updateChecklistenEingaben]);

  useEffect(() => {
    loadRows();
  }, [loadRows]);

  useEffect(() => {
    setCurrentTitle(betriebName);
    setCurrentVvvo(betriebVvvo);
  }, [setCurrentTitle, setCurrentVvvo, betriebVvvo, betriebName]);

  useEffect(() => {
    if (shouldLoadChecklistenEingaben || checklistenEingaben.some(checkliste => checkliste.eingabe === "")) {
      loadChecklistenEingaben(betriebId);
      loadChecklisten();
    }
  }, [
    betriebId,
    checklistenEingaben,
    loadChecklisten,
    loadChecklistenEingaben,
    shouldLoadChecklistenEingaben,
  ]);

  useEffect(() => {
    if ((betrieb.checklistenEingabenLoadedCount ?? 0) < (betrieb.checklistenEingabenCount ?? 0)) {
      loadChecklistenEingaben(betriebId);
    }
  }, [betrieb, betriebId, loadChecklistenEingaben]);

  useEffect(() => {
    // Get pdf from the server and download with one click on the button
    if (checklistenEingabePdf && checklistenEingabePdf.data) {
      const { data, filename } = checklistenEingabePdf;
      const pdfUrl = convertBase64ToPdf(data);
      generateLinkForPdf(pdfUrl, filename);

      // Remove pdf after downloading to prevent errors with different pdf.
      resetPdfChecklistenEingabe();
    }
  }, [checklistenEingabePdf, resetPdfChecklistenEingabe]);

  useEffect(
    () => () => {
      setCurrentVvvo("");
    },
    [setCurrentVvvo]
  );

  const tableChecklistenEingaben = useCallback(
    () => (
      <>
        <MaterialTable
          actions={[
            {
              icon: "draft",
              onClick: (event, rowData) => rowData,
            },
            {
              icon: "copy",
              onClick: (event, rowData) => rowData,
            },
            {
              icon: "PDF",
              onClick: (event, rowData) => rowData,
            },
            {
              icon: "delete",
              tooltip: "Delete User",
              onClick: (event, rowData) => rowData,
            },
          ]}
          icons={{
            ResetSearch: forwardRef(() => <div />),
            Search: forwardRef(() => <div />),
            SortArrow: forwardRef<SVGSVGElement>((iconProps, ref) => (
              <ArrowDownward {...iconProps} ref={ref} />
            )),
          }}
          columns={state.columns}
          data={state.data}
          title={""}
          options={{
            showEmptyDataSourceMessage: false,
            paging: false,
            headerStyle: {
              backgroundColor: "white",
              color: Colors.Grey,
              fontWeight: "bold",
              fontSize: "16px",
            },
            rowStyle: {
              fontSize: "0.875rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
            },
            searchFieldStyle: {
              backgroundColor: "white",
              border: `1px solid ${Colors.Grey}`,
              borderRadius: "4px",
              width: "300px",
              height: "40px",
              color: Colors.Grey,
              marginRight: "100px",
            },
            actionsColumnIndex: -1,
            draggable: false,
            search: true,
            sorting: true,
          }}
          localization={{
            header: {
              actions: "",
            },
            toolbar: {
              searchPlaceholder: t("Suche..."),
            },
          }}
          components={{
            Action: actionProps => (
              <div className={classes.tableControls}>
                {actionProps.action.icon === "draft" && (
                  <Button
                    className={actionProps.data.isDraft ? classes.syncedButton : classes.unsyncedButton}
                    disabled={!actionProps.data.isDraft}
                    data-cy="draft-button"
                    onClick={() => onDraftClick(actionProps.data)}
                  >
                    <EditIcon className={classes.actionIcon} />
                  </Button>
                )}
                {actionProps.action.icon === "copy" && (
                  <Button
                    className={
                      !actionProps.data.isDraft && !actionProps.data.isOutdated
                        ? classes.syncedButton
                        : classes.unsyncedButton
                    }
                    disabled={actionProps.data.isDraft || actionProps.data.isOutdated}
                    data-cy="copy-button"
                    onClick={() => handleCopyButton(actionProps.data)}
                  >
                    <FileCopyIcon className={classes.actionIcon} />
                  </Button>
                )}
                {actionProps.action.icon === "PDF" && (
                  <Button
                    className={
                      actionProps.data.synced && !actionProps.data.isDraft
                        ? classes.syncedButton
                        : classes.unsyncedButton
                    }
                    disabled={actionProps.data.isDraft || !actionProps.data.synced}
                    onClick={() => handleOpenChecklisteAsPdf(actionProps.data.uniqueId)}
                    data-cy="PDF-button"
                  >
                    <PictureAsPdfIcon className={classes.actionIcon} />
                  </Button>
                )}
                {actionProps.action.icon === "delete" && (
                  <Button
                    className={classes.deleteChecklistButton}
                    onClick={() => handleSetId(actionProps.data.uniqueId)}
                    data-cy="delete-row-button"
                  >
                    <DeleteIcon className={classes.actionIcon} />
                  </Button>
                )}
              </div>
            ),
            Toolbar: toolbarProps => (
              <div className={classes.tableToolbar}>
                <AppButton
                  className={classes.deleteButton}
                  handler={deleteBusinessPremisesHandler}
                  size={ButtonSize.LARGE_BUTTON}
                >
                  {t("BETRIEBSSTÄTTE LÖSCHEN")}
                </AppButton>
                <MTableToolbar {...toolbarProps} />
                <AppButton
                  className={classes.addButton}
                  handler={() => setIsAddChecklisteOpen(true)}
                  size={ButtonSize.LARGE_BUTTON}
                >
                  + {t("NEUE CHECKLISTE")}
                </AppButton>
              </div>
            ),
          }}
        />
        {isOpenDeleteBusinessPremises && (
          <DeleteBusinessPremisesContainer
            open={isOpenDeleteBusinessPremises}
            closeModal={deleteBusinessPremisesHandler}
            betriebId={betriebId}
          />
        )}
        {isDraftDialogOpen && (
          <OpenDraftContainer
            open={isDraftDialogOpen}
            closeModal={() => setIsDraftDialogOpen(!isDraftDialogOpen)}
            draftChecklistenEingabe={draftChecklistenEingabe}
            betriebName={betriebName}
            betriebVvvo={betriebVvvo}
          />
        )}
        {isCopyDialogOpen && (
          <CopyChecklistenEingabeContainer
            open={isCopyDialogOpen}
            closeModal={() => setIsCopyDialogOpen(!isCopyDialogOpen)}
            checklistenEingabeForCopy={checklistenEingabeForCopy}
            betriebName={betriebName}
            betriebVvvo={betriebVvvo}
          />
        )}
      </>
    ),
    [
      betriebId,
      betriebName,
      betriebVvvo,
      checklistenEingabeForCopy,
      classes.actionIcon,
      classes.addButton,
      classes.deleteButton,
      classes.deleteChecklistButton,
      classes.syncedButton,
      classes.tableControls,
      classes.tableToolbar,
      classes.unsyncedButton,
      deleteBusinessPremisesHandler,
      draftChecklistenEingabe,
      handleCopyButton,
      handleOpenChecklisteAsPdf,
      isCopyDialogOpen,
      isDraftDialogOpen,
      isOpenDeleteBusinessPremises,
      onDraftClick,
      state.columns,
      state.data,
      t,
    ]
  );

  const handleSetId = (uniqueId: string) => {
    setOpen(true);
    setChecklistenEingabeUniqueId(uniqueId);
  };

  const handleWindow = () => {
    setOpen(false);
  };

  const handleDeleteCheckliste = () => {
    deleteChecklistenEingabe(checklistenEingabeUniqueId, betriebId);
    setOpen(false);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const addChecklisteButton = useCallback(
    () => (
      <Paper>
        {isAddChecklisteOpen && (
          <AddChecklistenContainer
            betriebId={betriebId}
            betriebName={betriebName}
            betriebVvvo={betriebVvvo}
            open={isAddChecklisteOpen}
            setOpen={setIsAddChecklisteOpen}
          />
        )}
      </Paper>
    ),
    [betriebId, betriebName, betriebVvvo, isAddChecklisteOpen]
  );

  if (checklistenEingabenStatus.pending || logoutStatus.pending) {
    return (
      <>
        <AppBarContainer />
        <PreLoader />
      </>
    );
  }

  return checklistenEingaben.length !== 0 ? (
    <>
      <AppBarContainer />
      {addChecklisteButton()}
      {tableChecklistenEingaben()}
      {open && (
        <TransitionsModal closeModal={handleWindow} open={open}>
          <h2 className={classes.deleteChecklistDescription}>
            {t("Möchten Sie diese Datei unwiderruflich löschen?")}
          </h2>
          <div className={classes.buttonSection}>
            <AppButton handler={handleDeleteCheckliste}>{t("JA, LÖSCHEN")}</AppButton>
            <AppButton className={classes.cancelBtn} handler={onCloseModal}>
              {t("ABBRECHEN")}
            </AppButton>
          </div>
        </TransitionsModal>
      )}
    </>
  ) : (
    <>
      <AppBarContainer />
      {addChecklisteButton()}
      {tableChecklistenEingaben()}
      <Typography className={classes.emptyChecklisten} variant="h4" component="h2">
        {t("Keine Checklisten")}
      </Typography>
    </>
  );
};

export default ChecklistenOverviewComponent;
