import BackendClient from "../../api/backend-client";
import apiMethods from "../../api/api-methods";
import logger from "../../logger";
import RoutePaths from "../../routes/route-paths";
import AuthService from "../../shared/services/auth.service";
import { LogoutActionType, logoutSuccess, logoutFailure } from "./logout.actions";
import { showFailedRequest, showSuccessfulRequest } from "../notifications/notifications.actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { Dispatch } from "redux";
import { history } from "../../store/configureStore";
import { SuccessMessages, FailureMessages } from "../../shared/notifications-messages";
import I18n from "i18next";
import { removeLoginInfo } from "../login/login.actions";
import { resetBetriebe } from "../betriebe/betriebe.actions";
import { resetChecklistenEingabe } from "../checklisten-eingabe/checklisten-eingabe.actions";

let backendClient: BackendClient;
const logError = logger.error("logout.saga");
const authService = AuthService.getInstance();
const networkErrorMessage = "Network Error";

export function* logOut() {
  try {
    yield call([backendClient, apiMethods.Logout]);
    yield put(logoutSuccess(true));
    authService.endSession();
    yield put(removeLoginInfo());
    yield put(resetBetriebe());
    yield put(resetChecklistenEingabe());
    history.push(RoutePaths.Login);
    yield put(showSuccessfulRequest(I18n.t(SuccessMessages.SHOW_SUCCESSFUL_LOGOUT)));
  } catch (e) {
    logError(e.message);
    yield put(logoutFailure(e.message));
    authService.endSession();
    history.push(RoutePaths.Login);
    if (e.message !== networkErrorMessage) {
      yield put(showFailedRequest(I18n.t(FailureMessages.SHOW_FAILURE_LOGOUT)));
    }
  }
}

export default function* logoutSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeLatest(LogoutActionType.LOGOUT, logOut);
}
