import { Action } from "../action";
import { IPdfDto } from "../../api/backend-api-v2";
import { ISyncedChecklistenEingabeDto } from "../../db/database";

export interface DeleteChecklistenEingabeDto {
  uniqueId: string;
  betriebId: number;
}

export enum ChecklistenEingabeActionType {
  LOAD_CHECKLISTEN_EINGABEN = "LOAD_CHECKLISTEN_EINGABEN",
  LOAD_CHECKLISTEN_EINGABEN_SUCCESS = "LOAD_CHECKLISTEN_EINGABEN_SUCCESS",
  LOAD_CHECKLISTEN_EINGABEN_FAILURE = "LOAD_CHECKLISTEN_EINGABEN_FAILURE",
  LOAD_LATEST_CHECKLISTEN_EINGABEN = "LOAD_LATEST_CHECKLISTEN_EINGABEN",
  SAVE_CHECKLISTEN_EINGABE = "SAVE_CHECKLISTEN_EINGABE",
  SAVE_CHECKLISTEN_EINGABE_SUCCESS = "SAVE_CHECKLISTEN_EINGABE_SUCCESS",
  SAVE_CHECKLISTEN_EINGABE_FAILURE = "SAVE_CHECKLISTEN_EINGABE_FAILURE",
  SYNC_CHECKLISTEN_EINGABE = "SYNC_CHECKLISTEN_EINGABE",
  DELETE_CHECKLISTEN_EINGABE = "DELETE_CHECKLISTEN_EINGABE",
  DELETE_CHECKLISTEN_EINGABE_SUCCESS = "DELETE_CHECKLISTEN_EINGABE_SUCCESS",
  DELETE_CHECKLISTEN_EINGABE_FAILURE = "DELETE_CHECKLISTEN_EINGABE_FAILURE",
  GET_PDF_CHECKLISTEN_EINGABE = "GET_PDF_CHECKLISTEN_EINGABE",
  GET_PDF_CHECKLISTEN_EINGABE_SUCCESS = "GET_PDF_CHECKLISTEN_EINGABE_SUCCESS",
  GET_PDF_CHECKLISTEN_EINGABE_FAILURE = "GET_PDF_CHECKLISTEN_EINGABE_FAILURE",
  RESET_PDF_CHECKLISTEN_EINGABE = "RESET_PDF_CHECKLISTEN_EINGABE",
  SET_DRAFT_CHECKLISTEN_EINGABE = "SET_DRAFT_CHECKLISTEN_EINGABE",
  REMOVE_DRAFT_CHECKLISTEN_EINGABE = "REMOVE_DRAFT_CHECKLISTEN_EINGABE",
  SET_COPY_OF_CHECKLISTEN_EINGABE = "SET_COPY_OF_CHECKLISTEN_EINGABE",
  REMOVE_COPY_OF_CHECKLISTEN_EINGABE = "REMOVE_COPY_OF_CHECKLISTEN_EINGABE",
  UPDATE_CHECKLISTEN_EINGABE = "UPDATE_CHECKLISTEN_EINGABE",
  RESET_CHECKLISTEN_EINGABE = "RESET_CHECKLISTEN_EINGABE",
}

export const loadChecklistenEingaben = (betriebId: number): Action<number> => ({
  type: ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN,
  payload: betriebId,
});

export const loadChecklistenEingabenSuccess = (entities: {
  [betriebId: number]: ISyncedChecklistenEingabeDto[];
}): Action<{ [betriebId: number]: ISyncedChecklistenEingabeDto[] }> => ({
  type: ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN_SUCCESS,
  payload: entities,
});

export const loadChecklistenEingabenFailure = (errorMessage: string): Action<string> => ({
  type: ChecklistenEingabeActionType.LOAD_CHECKLISTEN_EINGABEN_FAILURE,
  payload: errorMessage,
});

export const loadLatestChecklistenEingaben = (entities: {
  [betriebId: number]: ISyncedChecklistenEingabeDto[];
}): Action<{ [betriebId: number]: ISyncedChecklistenEingabeDto[] }> => ({
  type: ChecklistenEingabeActionType.LOAD_LATEST_CHECKLISTEN_EINGABEN,
  payload: entities,
});

export const saveChecklistenEingabe = (
  checklistenEingabe: ISyncedChecklistenEingabeDto
): Action<ISyncedChecklistenEingabeDto> => ({
  type: ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE,
  payload: checklistenEingabe,
});

export const saveChecklistenEingabeSuccess = (
  checklistenEingabe: ISyncedChecklistenEingabeDto
): Action<ISyncedChecklistenEingabeDto> => ({
  type: ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE_SUCCESS,
  payload: checklistenEingabe,
});

export const saveChecklistenEingabeFailure = (errorMessage: string): Action<string> => ({
  type: ChecklistenEingabeActionType.SAVE_CHECKLISTEN_EINGABE_FAILURE,
  payload: errorMessage,
});

export const syncChecklistenEingabe = (): Action<null> => ({
  type: ChecklistenEingabeActionType.SYNC_CHECKLISTEN_EINGABE,
  payload: null,
});

export const deleteChecklistenEingaben = (
  ids: DeleteChecklistenEingabeDto
): Action<DeleteChecklistenEingabeDto> => ({
  type: ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE,
  payload: ids,
});

export const deleteChecklistenEingabenSuccess = (
  ids: DeleteChecklistenEingabeDto
): Action<DeleteChecklistenEingabeDto> => ({
  type: ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE_SUCCESS,
  payload: ids,
});

export const deleteChecklistenEingabenFailure = (errorMessage: string): Action<string> => ({
  type: ChecklistenEingabeActionType.DELETE_CHECKLISTEN_EINGABE_FAILURE,
  payload: errorMessage,
});

export const getPdfChecklistenEingabe = (id: string): Action<string> => ({
  type: ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE,
  payload: id,
});

export const getPdfChecklistenEingabeSuccess = (pdf: IPdfDto): Action<IPdfDto> => ({
  type: ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE_SUCCESS,
  payload: pdf,
});

export const getPdfChecklistenEingabeFailure = (errorMessage: string): Action<string> => ({
  type: ChecklistenEingabeActionType.GET_PDF_CHECKLISTEN_EINGABE_FAILURE,
  payload: errorMessage,
});

export const resetPdfChecklistenEingabe = (): Action<null> => ({
  type: ChecklistenEingabeActionType.RESET_PDF_CHECKLISTEN_EINGABE,
  payload: null,
});

export const setDraftChecklistenEingabe = (
  checklistenEingabe: ISyncedChecklistenEingabeDto
): Action<ISyncedChecklistenEingabeDto> => ({
  type: ChecklistenEingabeActionType.SET_DRAFT_CHECKLISTEN_EINGABE,
  payload: checklistenEingabe,
});

export const removeDraftChecklistenEingabe = (): Action<null> => ({
  type: ChecklistenEingabeActionType.REMOVE_DRAFT_CHECKLISTEN_EINGABE,
  payload: null,
});

export const setCopyOfChecklistenEingabe = (
  checklistenEingabe: ISyncedChecklistenEingabeDto
): Action<ISyncedChecklistenEingabeDto> => ({
  type: ChecklistenEingabeActionType.SET_COPY_OF_CHECKLISTEN_EINGABE,
  payload: checklistenEingabe,
});

export const removeCopyOfChecklistenEingabe = (): Action<null> => ({
  type: ChecklistenEingabeActionType.REMOVE_COPY_OF_CHECKLISTEN_EINGABE,
  payload: null,
});

export const updateChecklistenEingabe = (checklistenEingabe: {
  [betriebId: number]: ISyncedChecklistenEingabeDto[];
}): Action<{ [betriebId: number]: ISyncedChecklistenEingabeDto[] }> => ({
  type: ChecklistenEingabeActionType.UPDATE_CHECKLISTEN_EINGABE,
  payload: checklistenEingabe,
});

export const resetChecklistenEingabe = (): Action<null> => ({
  type: ChecklistenEingabeActionType.RESET_CHECKLISTEN_EINGABE,
  payload: null,
});
