enum apiMethods {
  CreateSession = "createSession",
  GetBetriebe = "getBetriebe",
  GetBetriebById = "getBetriebById",
  SaveBetrieb = "saveBetrieb",
  DeleteBetrieb = "deleteBetrieb",
  GetChecklisten = "getChecklisten",
  GetChecklistenEingaben = "getChecklistenEingaben",
  SaveChecklistenEingabe = "saveChecklistenEingabe",
  CreateUser = "createUser",
  AuthorizeNewBetriebVvvo = "authorizeNewBetriebVvvo",
  ChangePassword = "changePassword",
  ConfirmChangePassword = "confirmChangePassword",
  CheckVvvo = "checkVvvo",
  AuthorizeExistingBetriebVvvo = "authorizeExistingBetriebVvvo",
  ResetPassword = "resetPassword",
  Logout = "logout",
  PutContactDetails = "putContactDetails",
  ConfirmCreationEmail = "confirmCreationEmail",
  AuthorizeConfirmVvvo = "authorizeConfirmVvvo",
  AuthorizeRejectVvvo = "authorizeRejectVvvo",
  GetUserProfile = "getUserProfile",
  RequestChangeEmail = "requestChangeEmail",
  ConfirmChangeEmail = "confirmChangeEmail",
  CheckPassword = "checkPassword",
  DeleteChecklistenEingabe = "deleteChecklistenEingabe",
  ResendVvvoAuthorization = "resendVvvoAuthorization",
  ChangeUserLanguage = "changeUserLanguage",
  GetTranslations = "getTranslations",
  ValidateEmail = "validateEmail",
  GetPdfChecklistenEingabe = "getPdfChecklistenEingabe",
  ResendUserRegistrationEmail = "resendUserRegistrationEmail",
}
export default apiMethods;
