import { FunctionComponent, useEffect, useState, MouseEventHandler } from "react";
import { IBetriebDto } from "../../api/backend-api-v1";
import { createStyles, Theme, WithStyles, Box, TextField } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import RoutePaths from "../../routes/route-paths";
import AppBarContainer from "../../components/app-bar/app-bar.container";
import AppButton from "../../components/common/app-button/app-button";
import { ButtonSize } from "../../shared/buttons-style";
import { Colors } from "../../theme/colors";
import TransitionsModal from "../../components/common/modal/modal-window";
import { useDispatch } from "react-redux";
import { resendVvvoAuthorization } from "../../store/betriebstaette-auth/betriebstaette-auth.actions";
import DropDown from "../../components/drop-down/drop-down.component";
import farmIcon from "../../images/img/farm.png";
import { useTranslation } from "react-i18next";
import PreLoader from "../../components/preloader/preloader.component";
import { LoadingStatus } from "../../store/loading-status";
import { refreshBetriebe } from "../../store/betriebe/betriebe.actions";
import classnames from "classnames";
import { IBetriebWithChecklistenEingabenDto } from "../../api/backend-api-v2";
import { ISyncedChecklistenEingabeDto } from "../../db/database";
import { checkInternetConnection } from "../../utils/network-status";

export interface ConnectedState {
  betriebe: IBetriebDto[];
  betriebeStatus: LoadingStatus;
  translationsStatus: LoadingStatus;
  logoutStatus: LoadingStatus;
  checklistenEingaben: { [betriebId: number]: ISyncedChecklistenEingabeDto[] };
}

export interface ConnectedDispatch {
  navigateTo: (path: string) => void;
  setCurrentTitle: (title: string) => void;
  setCurrentVvvo: (vvvo: string) => void;
  showFailedRequest: (message: string) => void;
}

enum FilterValue {
  ALLE_BETRIEBSSTÄTTEN = "0",
  AUTORISIERTE_BETRIEBSSTÄTTEN = "1",
  NICHT_AUTORISIERTE_BETRIEBSSTÄTTEN = "2",
}
interface Props extends WithStyles<typeof styles>, ConnectedState, ConnectedDispatch {
  isAddBetriebOpen: boolean;
  setIsAddBetriebOpen: (isAddBetriebOpen: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    addBtnWrapper: {
      width: "100%",
      paddingTop: "20px",
      fontSize: "12px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    addBtn: {
      fontSize: "12pt",
      width: "23%",
      marginRight: "15px",
    },
    betriebeGrid: {
      display: "grid",
      gridAutoFlow: "row",
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
      justifyItems: "center",
      gridGap: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      alignContent: "stretch",
      height: "100%",
      paddingTop: "20px",
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    inactive: {
      backgroundColor: Colors.Grey,
      height: "50px",
      fontSize: "12pt",
      "&:hover": {
        opacity: 0.75,
      },
    },
    active: {
      backgroundColor: Colors.DarkGreen,
      height: "50px",
      fontSize: "12pt",
      "&:hover": {
        opacity: 0.75,
      },
    },
    reauthDescription: {
      textAlign: "center",
      padding: "0px 0 70px 0",
    },
    buttonSection: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    cancelBtn: {
      backgroundColor: Colors.Grey,
      "&:hover": {
        opacity: 0.75,
      },
    },
    searchField: {
      width: "30%",
      display: "flex",
      marginLeft: "15px",
    },
    betriebIcon: {
      color: Colors.Grey,
    },
    formControl: {
      width: "30%",
      border: `1px solid ${Colors.Grey}`,
    },
    inputRoot: {
      display: "flex",
      flexDirection: "row-reverse",
      height: "40px",
    },
    farmIcon: {
      marginLeft: "10px",
      width: "20px",
    },
    emptyResult: {
      marginLeft: "15px",
      color: Colors.Grey,
    },
    emptyBetriebIndicator: {
      position: "absolute",
      bottom: 5,
      right: 5,
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: Colors.White,
    },
  });

const BetriebeComponent: FunctionComponent<Props> = ({
  classes,
  betriebe,
  navigateTo,
  setCurrentTitle,
  setCurrentVvvo,
  betriebeStatus,
  logoutStatus,
  checklistenEingaben,
  showFailedRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [currentBetriebId, setCurrentBetriebId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [betriebFilter, setBetriebFilter] = useState(0);
  let timerId: NodeJS.Timeout;
  const { t } = useTranslation();
  const oneMinute = 60000;

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const FarmIcon = () => <img className={classes.farmIcon} src={farmIcon} alt="farm-logo" />;

  const handleWindow: MouseEventHandler = () => {
    setOpen(false);
  };

  const handleBetriebButton = (betrieb: IBetriebWithChecklistenEingabenDto, isEmptyBetrieb: boolean) => {
    const { id, name, vvvo, authorized } = betrieb;
    const isOnline = checkInternetConnection();

    if (isEmptyBetrieb && !isOnline) {
      showFailedRequest(t("Betrieb data was not loaded"));
      return;
    }

    if (id) {
      setCurrentBetriebId(id);
    }
    if (!authorized) {
      setOpen(true);
    } else {
      setCurrentVvvo(vvvo.value);
      navigateTo(`${RoutePaths.ChecklistenOverview}/${id}/${name}/${vvvo.value}`);
    }
  };

  const grid = (results: any) =>
    !!results &&
    results.map((betrieb: IBetriebWithChecklistenEingabenDto) => {
      const {
        id,
        name,
        vvvo,
        authorized,
        checklistenEingabenLoadedCount,
        checklistenEingabenCount,
      } = betrieb;
      // Defines if the Betrieb has any ChecklistenEingaben
      const isBetriebWithoutChecklistenEingabe =
        (checklistenEingabenLoadedCount ?? 0) < (checklistenEingabenCount ?? 0);
      // Defines if the ChecklistenEingaben for the Betrieb was loaded
      const isEingabeWasLoaded =
        !!Object.values(checklistenEingaben).length &&
        checklistenEingaben[id].filter(item => !!item.eingabe).length === checklistenEingabenCount;
      const isEmptyBetrieb = isBetriebWithoutChecklistenEingabe && !isEingabeWasLoaded;

      return (
        <div key={id} style={{ position: "relative" }}>
          <AppButton
            handler={() => handleBetriebButton(betrieb, isEmptyBetrieb)}
            size={ButtonSize.LARGE_BUTTON}
            className={classnames(!authorized ? classes.inactive : classes.active)}
          >
            {name}
            <br />
            VVVO {vvvo.value}
          </AppButton>
          {isEmptyBetrieb && <div className={classes.emptyBetriebIndicator} />}
        </div>
      );
    });

  useEffect(() => {
    setCurrentTitle(t("Meine Betriebsstätten"));
  }, [setCurrentTitle, t]);

  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const fitsSearchTerm = (betrieb: IBetriebDto) =>
      betrieb.vvvo.value.toLowerCase().includes(lowerCaseSearchTerm) ||
      betrieb.name.toLowerCase().includes(lowerCaseSearchTerm);

    let results: any = [];
    switch (betriebFilter.toString()) {
      case FilterValue.AUTORISIERTE_BETRIEBSSTÄTTEN:
        results = betriebe.filter((betrieb: IBetriebDto) => betrieb.authorized && fitsSearchTerm(betrieb));
        break;
      case FilterValue.NICHT_AUTORISIERTE_BETRIEBSSTÄTTEN:
        results = betriebe.filter((betrieb: IBetriebDto) => !betrieb.authorized && fitsSearchTerm(betrieb));
        break;
      default:
        results = betriebe.filter((betrieb: IBetriebDto) => fitsSearchTerm(betrieb));
        break;
    }
    setSearchResults(results);
    setBetriebFilter(betriebFilter);
  }, [searchTerm, betriebFilter, betriebe]);

  const requestReauth = () => {
    dispatch(resendVvvoAuthorization(currentBetriebId!));
    setOpen(false);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const openBetriebPage = () => {
    navigateTo(`${RoutePaths.BetriebstatteAuth}`);
  };

  const changeFilter = (filterValue: number) => {
    setBetriebFilter(filterValue);
  };

  timerId = setInterval(() => {
    dispatch(refreshBetriebe());
  }, oneMinute);

  useEffect(() => () => clearInterval(timerId), [timerId]);

  if (betriebeStatus.pending || logoutStatus.pending) {
    return (
      <>
        <AppBarContainer />
        <PreLoader />
      </>
    );
  }

  return (
    <>
      <AppBarContainer />
      <Box className={classes.addBtnWrapper}>
        <TextField
          variant="outlined"
          type="text"
          placeholder={t("Betriebsstätte")}
          value={searchTerm}
          onChange={handleChange}
          className={classes.searchField}
          InputProps={{ classes: { root: classes.inputRoot }, endAdornment: <FarmIcon /> }}
        />
        <DropDown filter={betriebFilter} handleFilter={(filterValue: number) => changeFilter(filterValue)} />
        <AppButton handler={openBetriebPage} className={classes.addBtn}>
          + {t("NEUE BETRIEBSSTÄTTE")}
        </AppButton>
      </Box>
      {open && (
        <TransitionsModal closeModal={handleWindow} open={open}>
          <h3 className={classes.reauthDescription}>{t("Autorisierungsanfrage")}</h3>
          <div className={classes.buttonSection}>
            <AppButton handler={requestReauth}>{t("JA")}</AppButton>
            <AppButton className={classes.cancelBtn} handler={onCloseModal}>
              {t("ABBRECHEN")}
            </AppButton>
          </div>
        </TransitionsModal>
      )}

      {betriebFilter === 0 && betriebe.length === 0 ? (
        <h2 className={classes.emptyResult}>{t("Keine Betriebsstätten gefunden")}</h2>
      ) : betriebFilter === 0 && searchTerm === "" ? (
        <div className={classes.betriebeGrid}>{grid(Object.values(betriebe))}</div>
      ) : !!searchResults && searchResults.length !== 0 ? (
        <div className={classes.betriebeGrid}>{grid(searchResults)}</div>
      ) : (
        <h2 className={classes.emptyResult}>{t("Keine Betriebsstätte entspricht den Filterkriterien")}</h2>
      )}
    </>
  );
};

export default withStyles(styles)(BetriebeComponent);
