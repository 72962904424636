import { FunctionComponent, useCallback, useEffect } from "react";
import { LoadingStatus } from "./store/loading-status";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./store/configureStore";
import routes from "./routes/routes";
import { SnackbarProvider } from "notistack";
import classNames from "classnames";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "./theme/colors";
import NotificationContainer from "./components/notifications/notifications.container";
import BrowserStorageContext from "./shared/context/context";
import Preloader from "./components/preloader/preloader.component";
import logger from "./logger";
import AuthService from "./shared/services/auth.service";
import Footer from "./components/footer/footer.component";
import * as Sentry from "@sentry/browser";

const logInfo = logger.info("app.component");
const logWarn = logger.warn("app.component");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      fontSize: theme.typography.body2.fontSize,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    snackbarSuccess: {
      backgroundColor: Colors.DarkGreen,
    },
    snackbarError: {
      backgroundColor: Colors.AmpelRot,
    },
    snackbarWarning: {
      backgroundColor: Colors.AmpelGelb,
    },
    snackbarInfo: {
      backgroundColor: Colors.SchmuckfarbeCyan,
    },
    wrapper: {
      minHeight: "calc(100vh - 25px)",
      marginBottom: "10px",
    },
  })
);
export interface ConnectedState {
  loginStatus: LoadingStatus;
  translationStatus: LoadingStatus;
  translationLoaded: boolean | undefined;
  isLoggedIn: boolean;
  allDataLoadingStatus: LoadingStatus;
}

export interface ConnectedDispatch {
  syncChecklistenEingabe: () => void;
  setIsAppUpdateAvailable: (isAvailable: boolean) => void;
  initTranslations: () => void;
  init: () => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const App: FunctionComponent<Props> = ({
  loginStatus,
  syncChecklistenEingabe,
  setIsAppUpdateAvailable,
  translationLoaded,
  initTranslations,
  translationStatus,
  isLoggedIn,
  allDataLoadingStatus,
  init,
}) => {
  const {
    REACT_APP_SYNC_INTERVAL,
    REACT_APP_ENVIRONMENT,
    REACT_APP_APP_VERSION,
    REACT_APP_SENTRY_URL,
  } = process.env;
  const classes = useStyles();
  const isPending = () => loginStatus.pending || translationStatus.pending || allDataLoadingStatus.pending;
  const authService = AuthService.getInstance();

  const registerMessageEventListener = useCallback(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", event => {
        if (event.data.type === "APP_UPDATE") {
          setIsAppUpdateAvailable(true);
        }
      });
    } else {
      logWarn("No serviceWorker available to register event-listener for app updates");
    }
  }, [setIsAppUpdateAvailable]);

  useEffect(() => {
    registerMessageEventListener();
  }, [registerMessageEventListener]);

  useEffect(() => {
    if (isLoggedIn || authService.isLoggedIn()) {
      syncChecklistenEingabe();
      const id = setInterval(
        () => {
          logInfo("sync checklisten eingabe");
          syncChecklistenEingabe();
        },
        REACT_APP_SYNC_INTERVAL ? +REACT_APP_SYNC_INTERVAL : 10000
      );
      return () => clearInterval(id);
    }
  }, [REACT_APP_SYNC_INTERVAL, syncChecklistenEingabe, isLoggedIn, authService]);

  useEffect(() => {
    if (!translationLoaded) {
      initTranslations();
    }
  }, [initTranslations, translationLoaded]);

  useEffect(() => {
    if (translationLoaded && (isLoggedIn || authService.isLoggedIn())) {
      init();
    }
  }, [authService, init, isLoggedIn, translationLoaded]);

  useEffect(() => {
    if (REACT_APP_ENVIRONMENT !== "#ENVIRONMENT#") {
      Sentry.init({
        dsn: REACT_APP_SENTRY_URL,
        release: REACT_APP_APP_VERSION,
        environment: REACT_APP_ENVIRONMENT,
      });
    }
  }, [REACT_APP_APP_VERSION, REACT_APP_ENVIRONMENT, REACT_APP_SENTRY_URL]);

  return (
    <BrowserStorageContext.Provider value={localStorage}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          variantSuccess: classNames(classes.snackbar, classes.snackbarSuccess),
          variantError: classNames(classes.snackbar, classes.snackbarError),
          variantWarning: classNames(classes.snackbar, classes.snackbarWarning),
          variantInfo: classNames(classes.snackbar, classes.snackbarInfo),
        }}
        hideIconVariant={true}
      >
        <div className={classes.wrapper}>
          <ConnectedRouter history={history}>{isPending() ? <Preloader /> : routes}</ConnectedRouter>
        </div>
        <Footer />
        <NotificationContainer />
      </SnackbarProvider>
    </BrowserStorageContext.Provider>
  );
};

export default App;
