import { connect } from "react-redux";

import { AppState } from "../../store/app.state";
import ChecklistenOverviewComponent from "./checklisten-overview.component";
import { ConnectedDispatch, ConnectedState } from "./checklisten-overview.types";
import {
  getChecklistenEingabenByBetriebId,
  getChecklistenEingabePdf,
} from "../../store/checklisten-eingabe/checklisten-engabe.selectors";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import {
  loadChecklistenEingaben,
  deleteChecklistenEingaben,
  getPdfChecklistenEingabe,
  resetPdfChecklistenEingabe,
} from "../../store/checklisten-eingabe/checklisten-eingabe.actions";
import {
  setCurrentTitle,
  setCurrentVvvo,
  shouldLoadChecklistenEingaben,
} from "../../store/common/common.actions";
import { loadChecklisten } from "../../store/checklisten/checklisten.actions";
import { getChecklistenEingabenStatus } from "../../store/checklisten-eingabe/checklisten-engabe.selectors";
import { getShouldLoadChecklistenEingaben } from "../../store/common/common.selectors";
import { getLogoutStatus } from "../../store/logout/logout.selectors";
import { getBetriebById } from "../../store/betriebe/betriebe.selectors";

const mapStateToProps = (
  state: AppState,
  props: RouteComponentProps<{ betriebId: string; betriebName: string; betriebVvvo: string }>
): ConnectedState => {
  const betriebId = parseInt(props.match.params.betriebId, undefined);
  const betriebName = props.match.params.betriebName;
  const betriebVvvo = props.match.params.betriebVvvo;
  return {
    checklistenEingaben: getChecklistenEingabenByBetriebId(state, betriebId),
    betrieb: getBetriebById(state, betriebId),
    betriebId,
    betriebName,
    betriebVvvo,
    checklistenEingabenStatus: getChecklistenEingabenStatus(state),
    checklistenEingabePdf: getChecklistenEingabePdf(state),
    shouldLoadChecklistenEingaben: getShouldLoadChecklistenEingaben(state),
    logoutStatus: getLogoutStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  loadChecklistenEingaben: (betriebId: number) => dispatch(loadChecklistenEingaben(betriebId)),
  setCurrentTitle: (title: string) => dispatch(setCurrentTitle(title)),
  setCurrentVvvo: (vvvo: string) => dispatch(setCurrentVvvo(vvvo)),
  deleteChecklistenEingabe: (uniqueId: string, betriebId: number) =>
    dispatch(deleteChecklistenEingaben({ uniqueId, betriebId })),
  loadChecklisten: () => dispatch(loadChecklisten()),
  getPdfChecklistenEingabe: (id: string) => dispatch(getPdfChecklistenEingabe(id)),
  resetPdfChecklistenEingabe: () => dispatch(resetPdfChecklistenEingabe()),
  updateChecklistenEingaben: (shouldUpdate: boolean) => dispatch(shouldLoadChecklistenEingaben(shouldUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistenOverviewComponent);
