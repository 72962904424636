import { Action } from "../action";

export enum InitActionType {
  INIT = "INIT",
  INIT_TRANSLATIONS = "INIT_TRANSLATIONS",
}

export const init = (loadAllData: boolean): Action<boolean> => ({
  type: InitActionType.INIT,
  payload: loadAllData,
});

export const initTranslations = (): Action<null> => ({
  type: InitActionType.INIT_TRANSLATIONS,
  payload: null,
});
