import { useState, useCallback } from "react";
import dayjs from "dayjs";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Chip from "@material-ui/core/Chip";
import { TableState, Row } from "./checklisten-overview.types";
import { useTranslation } from "react-i18next";
import { ISyncedChecklistenEingabeDto } from "../../db/database";

const useMaterialTableState = (
  checklistenEingaben: ISyncedChecklistenEingabeDto[],
  betriebId: number,
  classes: Record<"syncedIcon" | "unsyncedIcon" | "invalidChip" | "draftChip", string>
) => {
  const { t } = useTranslation();

  const [state, setState] = useState<TableState>({
    columns: [
      {
        title: <CloudUploadIcon />,
        field: "synced",
        render: prop => (
          <CheckCircleIcon className={prop.synced ? classes.syncedIcon : classes.unsyncedIcon} />
        ),
      },
      {
        title: t("Datum"),
        field: "createdAtUtc",
        customSort: (a, b) => dayjs(a.createdAtUtc).diff(b.createdAtUtc),
        defaultSort: "desc",
        render: rowDataDate => dayjs(rowDataDate.createdAtUtc).format("DD.MM.YYYY"),
      },
      {
        title: t("Verfasser"),
        field: "author",
      },
      {
        title: t("Checkliste"),
        field: "checklistenName",
        customSort: (a, b) =>
          a.checklistenName && b.checklistenName ? a.checklistenName.localeCompare(b.checklistenName) : 0,
      },
      {
        title: t("Beschreibung"),
        field: "description",
      },
      {
        title: t("Name"),
        field: "checklistenEingabeName",
        customSort: (a, b) => a.checklistenEingabeName.localeCompare(b.checklistenEingabeName),
      },
      {
        title: t("Status"),
        field: "status",
        render: prop => {
          const { isOutdated, isDraft } = prop;
          return isOutdated || isDraft ? (
            <Chip
              label={isOutdated ? t("VERALTET") : t("ENTWURF")}
              className={isOutdated ? classes.invalidChip : classes.draftChip}
            />
          ) : undefined;
        },
      },
    ],
    data: [],
  });

  const loadRows = useCallback(() => {
    const rowData: Row[] = [];

    for (let i = 0; i < checklistenEingaben.length; i++) {
      rowData[i] = {
        createdAtUtc: checklistenEingaben[i].createdAtUtc.toString(),
        checklistenName: checklistenEingaben[i].checklistenName,
        checklistenEingabeName: checklistenEingaben[i].checklistenEingabeName,
        id: checklistenEingaben[i] && checklistenEingaben[i].id,
        betriebId: checklistenEingaben[i].betriebId,
        checklistenId: checklistenEingaben[i].checklistenId,
        uniqueId: checklistenEingaben[i].uniqueId,
        eingabe: checklistenEingaben[i].eingabe,
        synced: checklistenEingaben[i].synced,
        author: checklistenEingaben[i].author!,
        description: checklistenEingaben[i].description!,
        isOutdated: checklistenEingaben[i].isOutdated,
        isDraft: checklistenEingaben[i].isDraft,
        isValid: checklistenEingaben[i].isValid,
      };
    }
    setState(s => ({ ...s, data: rowData }));
  }, [checklistenEingaben]);

  return { state, loadRows };
};

export default useMaterialTableState;
