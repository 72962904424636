import { AppState } from "../app.state";

export const getCurrentTitle = (state: AppState) => state.common.title;

export const getCurrentVvvo = (state: AppState) => state.common.vvvo;

export const getModalWindow = (state: AppState) => state.common.isOpen;

export const isAppUpdateAvailable = (state: AppState): boolean => state.common.isAppUpdateAvailable;

export const getCurrentLanguage = (state: AppState) => state.common.language;

export const getShouldLoadChecklistenEingaben = (state: AppState) =>
  state.common.shouldLoadChecklistenEingaben;

export const isAllChecklistenEingabenSynced = (state: AppState) =>
  state.common.isAllChecklistenEingabenSynced;

export const getAllDataLoadingStatus = (state: AppState) => state.common.allDataLoadingStatus;
