import { CommonActionType } from "./common.actions";
import { Action } from "../action";
import createReducer from "../createReducer";
import { LoadingStatus, loadInitial, loadPending, loadFailed, loadSuccess } from "../loading-status";

export interface CommonState {
  title: string;
  vvvo: string;
  isOpen: boolean;
  isAppUpdateAvailable: boolean;
  language: string;
  shouldLoadChecklistenEingaben: boolean;
  isAllChecklistenEingabenSynced: boolean;
  allDataLoadingStatus: LoadingStatus;
}

export const initialState: CommonState = {
  title: "",
  vvvo: "",
  isOpen: false,
  isAppUpdateAvailable: false,
  language: "de",
  shouldLoadChecklistenEingaben: false,
  isAllChecklistenEingabenSynced: false,
  allDataLoadingStatus: loadInitial(),
};

export default createReducer(initialState, {
  [CommonActionType.SET_CURRENT_TITLE](state: CommonState, action: Action<string>) {
    return { ...state, title: action.payload };
  },
  [CommonActionType.SET_CURRENT_VVVO](state: CommonState, action: Action<string>) {
    return { ...state, vvvo: action.payload };
  },
  [CommonActionType.OPEN_MODAL_WINDOW](state: CommonState, action: Action<boolean>) {
    return { ...state, isOpen: action.payload };
  },
  [CommonActionType.IS_APP_UPDATE_AVAILABLE](state: CommonState, action: Action<boolean>) {
    return { ...state, isAppUpdateAvailable: action.payload };
  },
  [CommonActionType.SET_CURRENT_LANGUAGE](state: CommonState, action: Action<boolean>) {
    return { ...state, language: action.payload };
  },
  [CommonActionType.SHOULD_LOAD_CHECKLISTEN_EINGABEN](state: CommonState, action: Action<boolean>) {
    return { ...state, shouldLoadChecklistenEingaben: action.payload };
  },
  [CommonActionType.CHECKLISTEN_EINGABEN_ARE_SYNCED](state: CommonState, action: Action<boolean>) {
    return { ...state, isAllChecklistenEingabenSynced: action.payload };
  },
  [CommonActionType.LOAD_ALL_DATA](state: CommonState) {
    return { ...state, allDataLoadingStatus: loadPending() };
  },
  [CommonActionType.LOAD_ALL_DATA_SUCCESS](state: CommonState) {
    return { ...state, allDataLoadingStatus: loadSuccess() };
  },
  [CommonActionType.LOAD_ALL_DATA_FAILURE](state: CommonState, action: Action<string>) {
    return { ...state, allDataLoadingStatus: loadFailed(action.payload) };
  },
});
